:root {
    --main-color: #077a16;
    --main-hover-color: #059e19;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
}

.main-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.main-container .panel-block {
    margin-bottom: 25px;
}

.panel-block {
    max-width: 220px;
}
.panel-block .ais-SearchBox-input {
    font-size: 14px;
}

.ais-RefinementList-showMore,
.ais-RefinementList-showMore--disabled {
    margin-top: 12px;
}

.ais-Pagination {
    margin-top: 1em;
}
.left-panel {
    float: left;
    width: 250px;
}
.right-panel {
    margin-left: 260px;
}

.header {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 50px;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    background-image: linear-gradient(284deg, #94ca02, #088d1b);
    color: #fff;
}

.header a {
    color: #fff;
    text-decoration: none;
}

.header-title {
    font-size: 1.2rem;
    font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
    font-weight: 900;
    font-style: italic;
    text-transform: uppercase;
    padding-top: 10px;
}

.header-subtitle {
    font-size: 1.2rem;
}

.main-container {
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
}

.ais-Highlight-highlighted {
    color: inherit;
    font-size: inherit;
}

#searchbox {
    margin-bottom: 2rem;
}

.query-results {
    margin: unset;
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 975px) {
    .query-results {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 650px) {
    .query-results {
        grid-template-columns: 1fr;
    }
}

.query-result-item {
    list-style-type: none;
    border: solid #1c351723 2px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 #365c2d44;
    display: flex;
    flex-direction: column;
    margin: unset;
    margin: 10px 8px;
    padding: 20px 15px;
    min-height: 390px;
    width: unset;
}

.hit-name {
    font-size: 1.2em;
    margin-top: 0;
}

.hit-info {
    text-align: left;
    margin-bottom: 18px;
}

.hit-fecha {
    text-decoration: none;
}

.hit-body-key {
    font-weight: 600;
}

.hit-contract {
    margin-bottom: 15px;
}

.hit-contract > * {
    flex-grow: 1;
}

.hit-button-container {
    margin-top: auto;
    text-align: right;
    padding-top: 20px;
}

.hit-show-more {
    background: var(--main-color);
    color: #fff;
    border: none;
    padding: 6px 10px;
    border-radius: 5px;
    flex-grow: 50;
    font-weight: bold;
}

.hit-show-more:hover {
    background: var(--main-hover-color);
    transition-duration: 0.2s;
    cursor: pointer;
}

.hit-modal-date {
    text-align: center;
    margin-bottom: 0px;
    color: black;
}

.ais-RefinementList-showMore {
    background: var(--main-color);
}

.ais-RefinementList-showMore:hover {
    background: var(--main-hover-color);
}

.ais-Pagination-item--selected .ais-Pagination-link {
    background-color: var(--main-color);
    border-color: var(--main-color);
    color: white;
}

.ais-Pagination-link {
    color: var(--main-color);
}

#hits-per-page {
    margin-top: 15px;
}

#donation-popup {
    display: none;
}

#donation-image {
    width: 100%;
    max-width: 750px;
}
